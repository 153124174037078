import { decodeToken } from "react-jwt";
import  Cryptr from "cryptr"
const cryptr = new Cryptr("014Gtg")

let token = "";

if(localStorage.getItem("at_config")){

  token =  cryptr.decrypt(localStorage.getItem("at_config"))

}

//return admin id
export const getUserId = async () => {

  if(localStorage.getItem("at_config")){
  const admin = await decodeToken(cryptr.decrypt(localStorage.getItem("at_config")))
  return admin.admin_id
  }


}

//decrypt token 
export const decrypted_token = () => {

  if(localStorage.getItem("at_config")){

    token =  cryptr.decrypt(localStorage.getItem("at_config"))
    return token
  }

}