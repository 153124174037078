import { GET_UNIQUE_ADMIN, GET_ADMINS } from '../../actions/types/adminTypes'

const initialState = {
    items: [], //existing elements
}

export default function(state = initialState, action) {

    switch (action.type) {
        case GET_ADMINS:
            return {
                ...state,
                items: action.payload
            }

        case GET_UNIQUE_ADMIN:
            return {
                ...state,
                items: action.payload
            }
        default:
              return state;
    }

}