import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import categoryReducer from './categories/categoryReducer'
import userReducer from "./userReducer/userReducer"
import adminReducer from "./adminReducer/adminReducer"
import commandePack from "./commandePackReducer/commandePackReducer"
import commandePackRental from "./commandePackRentalReducer/commandePackRentalReducer"
import forum from "./forumReducer/forumReducer"
import product from "./productReducer/productReducer"
import helpCenter from "./helpCenterReducer/helpCenterReducer"
import auctioneer from "./auctioneerReducer/auctioneerReducer"




const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  users: userReducer,
  admins: adminReducer,
  categories: categoryReducer,
  commandePack: commandePack,
  forum : forum,
  product: product,
  commandePackRental: commandePackRental,
  helpCenter: helpCenter,
  auctioneer: auctioneer
})

export default rootReducer
