import { GET_SUBJECTS, DELETE_SUBJECT } from '../../actions/types/forumTypes';

const initialState = {
    subjects: [], //existing elements
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload
            }
        case DELETE_SUBJECT:
            return {
              ...state,
              subjects: action.payload
            }
        default:
            return state;
    }
}