import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import api from "../../../configs/axios";
import { AuthContext } from "../../../auth-provider/auth-context";

class NavbarUser extends React.PureComponent {
  static contextType = AuthContext;

  state = {
    navbarSearch: false,
    suggestions: [],
    user: [],
    notifications: [],
  };

  getNotifs = async () => {
    await api
      .get("/admin-notifications/")
      .then((res) => {
        this.setState({ notifications: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async componentDidMount() {
    await this.getNotifs();
  }

  deleteReadedNotifs = async () => {
    await api
      .delete("/admin-notifications/")
      .then(() => {
        this.getNotifs();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    const { logout, user } = this.context;

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up">
              {" "}
              {this.state.notifications.length}{" "}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">
                  {this.state.notifications.length} Notifications
                </h3>
                {/* <span className="notification-title"></span> */}
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {this.state.notifications.map((obj, index) => (
                <div key={index} className="d-flex justify-content-between">
                  <Media className="d-flex align-items-start">
                    <Media left href="#">
                      <Icon.PlusSquare
                        className="font-medium-5 primary"
                        size={21}
                      />
                    </Media>
                    <Media body>
                      <Media heading className="primary media-heading" tag="h6">
                        {obj.title}
                      </Media>
                      <p className="notification-text">{obj.description}</p>
                    </Media>
                  </Media>
                </div>
              ))}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span
                  className="align-middle"
                  onClick={async () => {
                    await this.deleteReadedNotifs();
                  }}
                >
                  Marquer comme lu{" "}
                </span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown  tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {user.last_name} {user.first_name}
              </span>
            </div>
            <span data-tour="user">
              <Icon.User />
            </span>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" href="#" onClick={(e) => logout()}>
              <Icon.Power size={14} className="mr-50" />
              <span className="align-middle">
                Déconnexion
              </span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default NavbarUser;
