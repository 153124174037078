import { GET_COMMANDE_PACKS_RENTAL, GET_UNIQUE_COMMANDE_PACK_RENTAL, UPDATE_USER_PACK_RENTAL } from '../../actions/types/commandePackRentalTypes'

const initialState = {
    items: [], //existing elements
    selected: []
}

export default function(state = initialState, action) {

    switch (action.type) {
        case GET_COMMANDE_PACKS_RENTAL:
            return {
                ...state,
                items: action.payload
            }
        case GET_UNIQUE_COMMANDE_PACK_RENTAL:
            return {
                ...state,
                selected: action.payload
            }
        case UPDATE_USER_PACK_RENTAL:
            return {
                ...state,
                items: action.payload
            }
        default:
            return state;
    }

}