import { GET_UNIQUE_USER, GET_USERS, GET_USER_SALES, GET_USER_WINNINGS } from '../../actions/types/userTypes'

const initialState = {
    items: [], //existing elements
    sales: [],
    winnings: []
}

export default function(state = initialState, action) {

    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                items: action.payload
            }

        case GET_UNIQUE_USER:
            return {
                ...state,
                items: action.payload
            }
        case GET_USER_SALES:
            return {
                ...state,
                sales: action.payload
            }
        case GET_USER_WINNINGS:
            return {
                ...state,
                winnings: action.payload
            }
        default:
            return state;
    }

}