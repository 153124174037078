import { GET_COMMANDE_PACKS, GET_UNIQUE_COMMANDE_PACK, UPDATE_USER_PACK, GET_COMMANDE_REJECTED_PACKS, REJECT_COMMAND_PACK } from '../../actions/types/commandePackTypes'

const initialState = {
    items: [], //existing elements
    selected: []
}

export default function(state = initialState, action) {

    switch (action.type) {
        case GET_COMMANDE_PACKS:
            return {
                ...state,
                items: action.payload
            }
        case GET_UNIQUE_COMMANDE_PACK:
            return {
                ...state,
                selected: action.payload
            }
        case GET_COMMANDE_REJECTED_PACKS:
            return {
                ...state,
                items: action.payload
            }
        case UPDATE_USER_PACK:
            return {
                ...state,
                items: action.payload
            }
        default:
            return state;
    }

}