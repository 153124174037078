import { GET_PRODUCTS, GET_UNIQUE_PRODUCT, GET_PRODUCTS_BY_USER_ID } from '../../actions/types/productTypes';

const initialState = {
    products: [], //existing elements
    total: 0,
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                // total: action.payload
            }
        case GET_UNIQUE_PRODUCT:
            return {
                ...state,
                products: action.payload
            }
        case GET_PRODUCTS_BY_USER_ID:
            return {
                ...state,
                products: action.payload
            }
        default:
            return state;
    }
}