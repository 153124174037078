import React from 'react';
import { history } from "../history"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../assets/scss/plugins/extensions/toastr.scss"
import api from "../configs/axios"
import { getUserId } from "../configs/encryptDecrypt";
import  Cryptr from "cryptr"
const cryptr = new Cryptr("014Gtg")

export const AuthContext = React.createContext();

export class AuthProvider extends React.Component {

    state = {
        user: []
    }

    setUser = async () => {

      await api.get("/admin/auth/get-admin/" + getUserId()).then((res) => {
        this.setState({user : res.data})
      }).catch((err) => {
        console.log(err)
      })
    }

    async componentDidMount(){
      if(localStorage.getItem("at_config") !== null){
        await this.setUser()
      }else{
        history.push({
          pathname: "/login"
        })
      }
    }


    logout = () => {
      localStorage.clear()
      history.push({
        pathname: '/login'
      })
    }

    render() {
        const { children } = this.props
        const { user } = this.state
        const { setUser, getUser, logout, getCurrentAdmin } = this

        const notifyError = (error_msg) => toast.error(error_msg);
        return ( <AuthContext.Provider value = {
                {
                    user,
                    getCurrentAdmin,
                    login: async (email, password) => {
                      console.log(email, password)
                      await api.post("/admin/auth/sign-in", {
                          email: email,
                          password: password
                      }).then(async (res) => {
                          if(res.data.type === "error"){
                          notifyError(res.data.msg)

                          }else{
                            const encrypted_token = cryptr.encrypt(res.data.token)
                            await localStorage.setItem("at_config", encrypted_token)
                            await this.setUser();
                            history.push({
                            pathname: '/'
                          })
                          }
                      }).catch((err) => {
                          console.log(err)
                      })
                  },
                    setUser,
                    logout
                }
            } > { children }
            <ToastContainer />
            </AuthContext.Provider>
        )
    }

}