import { GET_HELP_CENTER, ADD_HELP_CENTER, EDIT_HELP_CENTER, DELETE_HELP_CENTER } from '../../actions/types/helpCenterTypes';

const initialState = {
    items: [], //existing elements
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_HELP_CENTER:
            return {
                ...state,
                items: action.payload
            }
        case ADD_HELP_CENTER:
            return {
                ...state,
                items: state + action.payload
            }
        case EDIT_HELP_CENTER:
            return {
                ...state,
                items: state
            }
        case DELETE_HELP_CENTER:
            return {
                ...state,
                items: state
            }
        default:
            return state;
    }
}