import { ADD_AUCTIONEER, GET_AUCTIONEERS, EDIT_AUCTIONEER, DELETE_AUCTIONEER } from '../../actions/types/auctioneerTypes'

const initialState = {
    items: [], //existing elements
}

export default function(state = initialState, action) {

    switch (action.type) {
        case GET_AUCTIONEERS:
            return {
                ...state,
                items: action.payload
            }
        case ADD_AUCTIONEER:
            return {
                ...state,
                items: action.payload
            }
        case EDIT_AUCTIONEER:
            return {
                ...state,
                items: action.payload
            }
        case DELETE_AUCTIONEER:
            return {
                ...state,
                items: action.payload
            }
        default:
            return state;
    }

}