import React from "react"
import * as Icon from "react-feather"
// CORRECT FILE
const navigationConfig = [
  {
    id: "home",
    title: "Tableau de bord",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "ProductsList",
    title: "Enchéres/Produits",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/products/list"
  },
  {
    id: "UsersList",
    title: "Utilisateurs",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/users/list"
  },
  {
    id: "AdminsList",
    title: "Administrateurs",
    type: "item",
    icon: <Icon.Shield size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admins/list"
  },
  {
    id: "Packs",
    title: "Packs",
    type: "collapse",
    icon: <Icon.Package size={20} />,
    children: [
        {
          id: "Commands",
          title: "Commandes",
          type: "item",
          navLink: "/packs/commandes",
          icon: <Icon.List size={20} />,
        },
        {
          id: "CommandsRental",
          title: "Location",
          type: "item",
          navLink: "/packs/commandes-rental",
          icon: <Icon.List size={20} />,
        },
        {
          id: "RejectedCommands",
          title: "Rejetées",
          type: "item",
          navLink: "/packs/rejected-commandes",
          icon: <Icon.X size={20} />,
        }
    ]
  },
  {
    id: "Forum",
    title: "Forum",
    type: "item",
    navLink: "/forum",
    icon: <Icon.HelpCircle size={20} />,
  },
  {
    id: "categories",
    title: "Categories",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    children : [
      {
        id: "list",
        title: "Liste",
        type: "item",
        navLink: "/categories",
        icon: <Icon.List size={20} />,
      }
      ,{
        id: "add",
        title: "Ajouter",
        type: "item",
        navLink : "/category/create",
        icon: <Icon.PlusCircle size={20} />,
      },

    ]
  },
  {
    id: "HelpCenter",
    title: "Centre d'aide",
    type: "item",
    navLink: "/help-center",
    icon: <Icon.Info size={20} />,
  },
  {
    id: "Auctioneer",
    title: "C. Priseurs",
    type: "item",
    navLink: "/auctioneers",
    icon: <Icon.Command size={20} />,
  },

]

export default navigationConfig
