import axios from "axios"
import { decrypted_token } from "./encryptDecrypt"
var api
var server_url = "https://server.zarai.com.dz/"
// var server_url = "http://localhost:5000"
if(localStorage.getItem("at_config")){
   api = axios.create({
    baseURL: server_url,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Method": "DELETE, POST, GET, OPTIONS, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "x-access-token": decrypted_token(),
        "api-key": "slfMM#MCYkC82G2_"
    },
    responseType: "json",
})
}else{
   api = axios.create({
    baseURL: server_url,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Method": "DELETE, POST, GET, OPTIONS, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "api-key": "slfMM#MCYkC82G2_"
    },
    responseType: "json",
})
}


export default api