import { CREATE_CATEGORY, GET_CATEGORIES, GET_SUBS, GET_UNIQUE_CATEGORIE } from '../../actions/types/categories'

const initialState = {
    items: [], //existing elements
    subs: [],
    redirect: "",
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                items: action.payload
            }
        case CREATE_CATEGORY:
            return {
                ...state,
                redirect: action.payload
            }
        case GET_UNIQUE_CATEGORIE:
            return {
                ...state,
                items: action.payload
            }
        case GET_SUBS:
            return {
                subs: action.payload
            }
        default:
            return state;
    }
}